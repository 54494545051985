<template>
  <div  >
    <BreadCrumbs>خانه > تقویم نمایشگاهی</BreadCrumbs>
    <transition enter-active-class="animate__animated animate__fadeIn" appear>
      <div >
        <h1 style="font-size: 28px" class="mt-8 mb-2 primary--text text-center font-weight-regular" >تقویم نمایشگاهی</h1>
        <h2 class="catssubtitle font-weight-regular">بانک جامع اطلاعاتی از نمایشگاه های داخلی و خارجی</h2>

        <v-container class="catscontanier">

          <p class="mb-2 mr-3" style="font-size: 16px">نوع نمایشگاه :</p>
          <div class="editwork">
          <v-select
              label="همه"
              solo
              v-model="selectedCatId"
              :items="internals"
              item-value="id"
              item-text="title"
              height="52"
              class="rounded-0"
          >
            <template v-slot:append>
                          <img src="../assets/image/Cat/icon-down.png" height="8.4" width="14.4"/>
            </template>
          </v-select>
          </div>

          <div class="catscontant" v-if="works" >

            <p v-if="works.length>0" class="primary--text ma-0" style="font-size: 14px" >لیستی از نمایشگاه های ثبت شده در سامانه ایران آی سیب موجود است.</p>
            <div v-if="works.length>0">
              <div class="catscontantitem pl-mm-6" v-for="work in works" :key="work.id" >
                <div class="calendaritemimage">
                  <img v-if="work.image" :src="$store.state.thumbnail + work.image" width="100%" style="object-fit: cover;"/>
                  <img alt="لوگوی ایران آی سیب" title="ایران آی سیب" v-else src="../assets/image/Header/logo.png" style="max-width: 70%;opacity: 0.2"/>
                </div>
                <div class="mr-2 mt-mm-0 mb-3 mb-md-0 mr-lg-2 cont1size">
                  <div class="d-flex flex-wrap"><p class="propertieskey ml-2">عنوان :</p><span v-if="work.title" class="propertiesvalue">{{work.title}}</span><span v-else class="propertiesvalue" style="font-weight: normal">ثبت نشده</span></div>
                  <div class="my-2 d-flex justify-space-between flex-wrap"><p class="grey--text ma-0" style="font-size: 12px;text-align: justify;text-justify: inter-word">{{work.desc}}</p></div>
                </div>
                <div class="mb-3 mb-md-0 mr-mm-16 cont2size">
                  <div class="d-flex justify-space-between flex-wrap"><p class="propertieskey">شماره تماس :</p><span v-if="work.tell" class="propertiesvalue" style="font-weight: normal">{{work.tell}}</span></div>
                  <div class="my-2 d-flex justify-space-between flex-wrap"><p class="propertieskey">تاریخ برگزاری :</p><span v-if="work.time" class="propertiesvalue" style="font-weight: normal">از تاریخ {{work.time | momentDate}} تا {{work.time2 | momentDate}}</span></div>
                  <p style="width: 100%;" v-if="work.address" class="propertieskey ml-2">آدرس : <span style="text-align: justify;text-justify: inter-word;font-weight: normal" class="propertiesvalue">{{work.address}}</span></p>
                </div>
              </div>
              <div>
                <v-btn
                    class="btn mt-2 v-btn--block"
                    style="font-size: 16px !important;"
                    @click="nextpage"
                    :loading="loading"
                    v-if="showmore"
                >
                  نمایش شرکت های بیشتر
                </v-btn>
              </div>
            </div>
            <p v-if="!workloading & works.length===0" class="text-center mt-10" >موردی جهت نمایش وجود ندارد</p>
            <div v-if="workloading" class="d-flex justify-center">
              <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>

          </div>
        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import axios from "axios";
import {momentfilter} from "../plugins/moment";

export default {
  name: 'App',
  mixins : [momentfilter],
  data() {
    return {
      selectedCatId : null,
      submittedcatId : null,
      works : [],
      workloading : true,
      showmore :false,
      page : 1,
      loading : false,
      internal : null,
      internals : [
        {
          title : 'همه',
          id : null
        },
          {
        title : 'داخلی',
        id : 1
      },
        {
          title : 'خارجی',
          id : 0
        },
      ]
    }
  },
  components : {
    BreadCrumbs,
  },
  metaInfo() {
    return {
      title:'تقویم نمایشگاهی',
      link: [{rel: 'canonical', href: this.$store.state.url+'/calendar'}]
    }
  },
  created() {

    this.fetchCalendar();

  },
  watch : {
    selectedCatId(){
      this.page = 1;
      this.fetchCalendar();
    }
  },
  methods : {

    nextpage(){
      this.loading=true;
      this.page = this.page + 1;
      axios.get(this.$store.state.api + 'allcalendar', {
        params: {
          page : this.page,
          internal : this.submittedcatId,
        }
      }).then(response => {
        this.works.push(...response.data.data);
        axios.get(this.$store.state.api + 'allcalendar', {
          params: {
            page : this.page + 1,
            internal : this.submittedcatId,
          }
        }).then(response => {
          if(response.data.data.length==0){
            this.showmore=false;
            this.loading=false;
          } else {
            this.showmore=true;
            this.loading=false;
          }
        })

      })
    },
    fetchCalendar(){
      this.workloading = true;
      this.works = [];
      axios.get(this.$store.state.api + 'allcalendar',
          {params : {
              page : this.page,
              internal:this.selectedCatId,
            }}
      ).then(response => {
        this.works = response.data.data;
        this.submittedcatId = this.selectedCatId;
        axios.get(this.$store.state.api + 'allcalendar', {
          params: {
            page : this.page + 1,
            internal:this.selectedCatId,
          }
        }).then(response => {
          if(response.data.data.length==0){
            this.showmore=false;
          } else {
            this.showmore=true;
          }
        })
        this.workloading = false;
      })
    }
  },


};
</script>

<style lang="scss">

@import "../assets/css/loading";

.catssearch{
  display: block;
  padding: 0 5px;
  max-width: 870px;
  margin: 20px auto 18px;
}

.catssearchprp {
  display: flex;
  padding: 0 5px;
  max-width: 870px;
  margin: 20px auto 18px;
}

.catssearchprp .v-label{
  color: #570654 !important;
  font-size: 16px ;
  height: 50px !important;
}

.catssearchprp .v-input{
  color: #570654 !important;
  font-size: 16px !important;
}

.catssearchprp .v-input__slot {
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08) !important;
  padding: 0 16px !important;
}

.catssearchprp .theme--light.v-input input, .theme--light.v-input textarea{
  color: #570654 !important;
}

.catssearchprp .v-list * , ::before , ::after {
  color: #570654 !important;
}

.catssearchprp .theme--light.v-select .v-select__selections{
  color: #570654 !important;
  line-height : 30px;
  background-color: #f0f0f0;
}

.catssearchprp .v-input__slot{
  background-color: #f0f0f0 !important;
}

.catssearch .v-input__slot{
  background: #570654 !important;
}

.catssearch .v-label{
  color: #fff !important;
  font-size: 18px ;
  height: 40px !important;
}

.catssearch .v-input{
  color: #fff !important;
  font-size: 18px !important;
  text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.6) !important;
}

.catssearch .v-input__slot {
  box-shadow: none !important;
  padding: 0 20px !important;
}

.catssubtitle{
  font-size: 17px;
  color: #aaaaaa;
  text-align: center;
}

.catitem{
  width: 220px;
  height: 234px;
  margin: 12px 25.6px;
  background-color: #f9f9f9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  user-select: none;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08);
}

.catscontant{
  min-height: 140px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08);
  margin-bottom: 140px;
  padding: 28px 32px 22px 32px;
}

.catscontantitem {
  margin: 24px 0;
  border-radius: 8px;
  background-color: #fdfdfd;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  min-height: 134px;
  padding: 4px 6px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08);
}

.cont1size {
  width: 100%;
}

.cont2size {
  width: 100%;
}





.calendaritemimage{
  border : 3px solid #f9f9f9;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1024px) {

  .calendaritemimage {
    height: 125px ;
  }

}

.mt-mm-0{
  margin-top: 12px;
}

.mr-mm-16{
  margin-right: 8px;
}

@media only screen and (min-width: 1024px) {
  .mt-mm-0{
    margin-top: 0 !important;
  }

  .mr-mm-16{
    margin-right: 32px !important;
  }
}


@media only screen and (min-width: 1024px) {

  .catscontantitem {
    flex-wrap: nowrap;
  }

.flex-mm-nowrap{
  flex-wrap: nowrap;
}

  .cont1size {
    width: 436px;
  }

  .cont2size {
    width: 380px;
  }

  .calendaritemimage {
    min-height: 125px;
  }

  .pl-mm-6{
    padding-left: 24px;
  }

}

@media only screen and (min-width: 960px) {

  .catscontantitem {
    justify-content: center;
  }

}

.morecontentbtn{
  margin-right: 10px;
}

@media only screen and (min-width: 1191px) {
  .morecontentbtn {
    margin-right: 50px;
  }
}

.catscontanier{
  padding: 0px 5px;
}

@media only screen and (min-width: 600px) {
  .catscontanier{
    padding: 0px 25.6px;
  }
}

.activecat {
  background-color: #e1e1e1;
}


</style>

<style lang="scss" >
.editwork .error--text fieldset {
  border-color: #ff6969 !important;
}

.editwork .v-text-field .v-text-field__details *{
  font-size: 12px !important;
  color: #ff6969 !important;
}

.editwork .address .v-text-field .v-text-field__details *{
  font-size: 12px !important;
}


.editwork .v-list * , ::before , ::after {
  color: #570654 !important;
}

.editwork .theme--light.v-select .v-select__selections{
  color: #570654 !important;
  line-height : 30px;
  background-color: #f6f6f6;
}

.editwork .v-select .v-input__append-inner{
  margin-top: 4px !important;
}

.editwork .v-select .v-input__slot{
  background-color: #f6f6f6 !important;
}

.editwork .v-select .v-label{
  color: #000 !important;
}

.editwork .v-select .v-input__slot {
  box-shadow: none !important;
  border : 1px solid #e8e8e8;
}

.editwork .socialdata .v-input__slot {
  padding-right: 4px !important;
}

.editwork .socialdata .v-input * {
  font-size: 14px !important;
  text-align: left !important;
}

</style>